const viewTypes = {
  Map: "Map",
  Search: "Search",
  List: "List",
  ListView: "ListView",
  Scheduler: "Scheduler",
  Analytics: "Analytics",
  ThirdPartyTransactionList: "ThirdPartyTransactionList",
  StatusExplorer: "StatusExplorer",
  ExternalDataExplorer: "ExternalDataExplorer",
  FileExplorer: "FileExplorer",
  PositionExplorer: "PositionExplorer",
  SmartPosition: "SmartPosition",
  Dashboard: "Dashboard",
  Subscriptions: "Subscriptions",
  AddOns: "AddOns",
  Users: "Users",
  ThirdParties: "ThirdParties",
  Objects: "Objects",
  Views: "Views",
  Templates: "Templates",
  Actions: "Actions",
  Widgets: "Widgets",
  Rules: "Rules",
  TransactionImportScheduleManagement: "TransactionImportScheduleManagement",
  DocumentTemplates: "DocumentTemplates",
  Activities: "Activities",
  Commodities: "Commodities",
  Comments: "Comments",
  Services: "Services",
  Statuses: "Statuses",
  Criteria: "Criteria",
  Equipment: "Equipment",
  Questions: "Questions",
  Racks: "Racks",
  Routes: "Routes",
  Tanks: "Tanks",
  KPI: "KPI",
  OperationalParameters: "OperationalParameters",
  GetShipData: "GetShipData",
  RiskWeighting: "RiskWeighting",
};

export default viewTypes;
